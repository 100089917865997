<template>
    <div>
        <v-toolbar flat color="white">
            <v-toolbar-title class="dodo">ألايميلات</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>



        </v-toolbar>
        <v-data-table :headers="headers" :items="mails" class="elevation-1"  total-items="15" hide-default-footer
  disable-pagination>

            <template slot="items" slot-scope="props">

                <td>{{props.item.email}}</td>
                <td class="text-xs-right">{{ props.item.created_at }}</td>
<!-- 

                <td class="justify-center layout px-0">
                    <v-icon small class="mr-2" @click="editItem(props.item)">
                        edit
                    </v-icon>
                    <v-icon small @click="deleteItem(props.item)">
                        delete
                    </v-icon>
                </td> -->
            </template>
            <template v-slot:[`item.actions`]="{ item }">

<!-- <v-icon
    small
    class="mr-2"
    @click="editItem(item)"
  >
  تعديل
  </v-icon>

  | -->
  <v-icon
    small
    @click="deleteItem(item)"
  >
    حذف
  </v-icon>
  


</template>
            <template slot="no-data">
                <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>
        </v-data-table>
        <v-pagination class="pagination" total-visible="20" v-model="page"
      color="#c7000b" style="position: relative; top: 20px;" circle=""
      :length="pageCount">
    </v-pagination>
    </div>
</template>


<script>
import axios from 'axios';
    export default {
        data() {
            return {
                attachments: [],
                form: new FormData,
                dialog: false,
                cats_positions: [],
                imageFile: '',
                arts: [],
                page: 1,
        pageCount: 0,
        current_page: 1,
        last_page: 0,
                view_ats: [],
                mails: [],
                headers: [

                    {
                        text: 'ألايميل ',
                        value: 'email',
                        sortable: false,
                    },
                    {
                        text: 'تاريخ الانظمام',
                        value: 'updated_at'
                    },
                    {
                        text: 'Actions',
                        value: 'actions',
                        sortable: false
                    }

                ],
                data: '',
                desserts: [],
                editedIndex: -1,
                editedItem: {

                },
                defaultItem: {

                },


            }
        },

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'أضافه  قسم جديد' : 'تعديل القسم'
            },selected: function () {
        return this.getMoreitems();
      }
        },

        watch: {
            selected: 'search by sub_cat_id',
        },
        dialog(val) {
            val || this.close()
        },


        created() {

            Fire.$on('cats_fliter', () => {

                axios.get('cat_by_positionid/' + this.editedItem.cat_pos_id.id).then(response => (this
                    .cats = response.data))

            });



            this.cat_position()
            this.initialize()





        },

        methods: {


            getMoreitems() {
        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
          this.initialize();

        }
      },


            // initialize() {
            //     axios.get('mails').then((d)=>{
            //         this.mails = d.data.data;
            //         this.last_page = d.data.last_page;
            // this.pageCount = d.data.last_page;
            //         //console.log(d.data.data);
            //     });
                
            // },



            initialize() {

                axios.get('mails?page=' + this.current_page,{
                headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorizationss: "Bearer " + this.$store.state.AdminInfo.token
                }
                })



                .then(d => {
                    this.mails = d.data.data;
                    this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
                })


                },



            editItem(item) {

                this.editedIndex = this.mails.indexOf(item)
                this.editedItem = Object.assign({}, item)

                this.dialog = true

                if (this.imageUrl[0] == null) {

                    this.img_old = this.editedItem.main_img;
                    this.imageUrl = 'img_art/' + this.img_old;

                }
            },

            deleteItem(item) {


                const index = this.desserts.indexOf(item)
                //confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)


                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    // Send request to the server
                    if (result.value) {
                        axios.delete('mails/' + item.id,{
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  }).then(() => {
                    this.$swal(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            this.initialize()
                        }).catch(() => {
                            this.$swal("Failed!", "There was something wronge.", "warning");
                        });
                    }
                })



            },

            close() {
                this.dialog = false
                this.imageUrl = '';
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                }, 300)
            },

            save() {


                if (this.editedIndex > -1) {



                } else {

                    axios.post('cats', {
                        cat_name: this.editedItem.cat_name,
                        cats_position_id: this.editedItem.cats_position_id,

                    }).then(() => {

                        this.dialog = false
                        Fire.$emit('AfterCreate');
                        toast({
                                type: 'success',
                                title: 'Signed in successfully'
                            })

                            //Send Photo To Server


                            .catch(() => {});

                    }).catch(() => {});
                    this.initialize();

                }
                this.close()
            }
        },

        mounted() {
            this.initialize();

        }

    }

</script>
