<template>
  <div>
    <v-toolbar flat color="white">
      <v-toolbar-title class="dodo">من نحــن</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
    
       <v-spacer></v-spacer>

      <v-dialog v-model="dialog" persistent max-width="900px">

        <!--<v-btn slot="activator" color="primary" dark class="dodo mb-2" >مقاله جديده</v-btn>-->

         <v-form v-model="valid" ref="form">
        <v-card>
           
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                  
                <v-flex xs12 sm6 md12>
                  <v-text-field 
                  v-model="editedItem.title"
                   v-validate="'required'" 
                    required   
                    :rules="titleRules"
                  class="form_label" label="العنوان"></v-text-field>
                </v-flex>




                <v-flex xs12 sm12 md12>
                   <v-textarea
                   
                    v-model="editedItem.details"
                    autofocus
                    browser-autocomplete
                    label="التفاصيـــل"
                    data-vv-name="name"
                    :rules="detailsRules"
                    > 
                  </v-textarea>
                </v-flex>


 </v-layout>
     
             
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" flat @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
        </v-form>
      </v-dialog>
    </v-toolbar>
    <v-data-table
    hide-default-footer
  disable-pagination
      :headers="headers"
      :items="abouts"
      class="elevation-1"
    
      
    >

      <template slot="items" slot-scope="props">
        <td>{{ props.item.id}}</td>
        <td>{{ props.item.title }}</td>
         <td>{{ props.item.details }}</td>
    
     
   
            
    
      </template>
      <template v-slot:[`item.actions`]="{ item }">
     
     <v-icon
         small
         class="mr-2"
         @click="editItem(item)"
       >
       تعديل
       </v-icon>
       <!-- | -->
       <!-- <v-icon
         small
         @click="deleteItem(item)"
       >
         حذف
       </v-icon> -->
       


</template>
    </v-data-table>
  </div>
</template>


<script>
import axios from 'axios'
  export default {

     props: [
            'settings'
        ],
    data  () {
      return {
      data: new FormData(),           
      errors: {},            
      percentCompleted: 0, 

        titleRules: [
          (v) => !!v || 'يجب ادخال عنوان من نحن',
         
        ],

          detailsRules: [
          (v) => !!v || 'يجب ادخال التفاصيل',
         
        ],

      
      form: new FormData,
      attachments:[],
      attached_images:[],
      form: new FormData,  
      valid: false,
      dialog: false,
      imagesUrl:'',
      imageName: '',
      img_old:'',
      posts_status:[],
      writers:[],
      imagesName:' ',
      search:null,
      filez:[],
      imageFile:[],
      imageUrl: '',
      journal_nums:[],
		  imageFile: '',
      abouts:[],
      view_ats:[],
      cats:[],
      headers: [

        
        {text: 'ID', value: 'id',sortable: false,},
        {text: 'ألعنوان',align: 'left', value: 'title',sortable: false, },
        { text: 'التفاصيل', value: 'details',sortable: false,},
        { text: 'Actions', value: 'actions', sortable: false } 

      ],
      
      desserts: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        email:'',
        phone: '',
        picsid:'',
        password: '',
        state_id:'',
      },
      defaultItem: {
        name: '',
        email:'',
        phone:'',
        password:'',
       
      },
       def: {
        name: 'sds',
    
       
      }

    }},

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'أضافه مقاله جديده' : 'Edit Item'
      }
    },

    watch: {


      
    },
      dialog (val) {
        val || this.close()
      },
    

    created () {
 
        this.initialize();
    
       
    },

    methods: {

        initialize(){

                  axios.get('about').then(response => (this.abouts = response.data)) 

        },


      deletepic(imgs){
  

        this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        // Send request to the server
                         if (result.value) {
                                axios.post('about_deleted/'+imgs.id).then(()=>{
                                  this.$swal(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        )
                                     this.initialize()
                                }).catch(()=> {
                                  this.$swal("Failed!", "There was something wronge.", "warning");
                                });
                         }
                    })


      },

       

      editItem (item) {

       
    

       this.editedIndex = this.abouts.indexOf(item)
       this.editedItem = Object.assign({}, item)
     
        this.dialog = true

             axios.get('attached_images/'+this.editedItem.id).then(response => (this.attached_images = response.data)) ;


            if(this.imageUrl[0]==null)
        {

            this.img_old= this.editedItem.main_img;
            this.imageUrl='img_art/thumb/'+this.img_old;
           
        }
      },

      deleteItem (item) {
       
        
        const index = this.desserts.indexOf(item)
        //confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)


        this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        // Send request to the server
                         if (result.value) {
                                axios.delete('abouts/'+item.id).then(()=>{
                                  this.$swal(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        )
                                     this.initialize()
                                }).catch(()=> {
                                  this.$swal("Failed!", "There was something wronge.", "warning");
                                });
                         }
                    })

                    

      },

      close () {
        this.dialog = false
        this.imageUrl='';
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        

        if (this.editedIndex > -1) {
    
          axios.post('about_update/'+this.editedItem.id, 
          {
                title:this.editedItem.title,
                details:this.editedItem.details,
     	
            },
          
          
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          }
          
          )

 
      
            
            
            .then(() => {

    
			  this.dialog = false        
        this.$swal.fire('Successfully', 'done', "success");

             
				   }).catch(() => {});
             this.initialize ();
            
        }
        
        else {

              if(this.$refs.form.validate())
          {
            
                //save New Art Here
                axios.post('about', {
                  
               title:this.editedItem.title,
               details:this.editedItem.details,
           
  
            },
          
          
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          }
            ).then(() => {



			  this.dialog = false
               Fire.$emit('AfterCreate');
               this.$swal.fire('Successfully', 'done', "success");

              
           }).catch(() => {});
         this.initialize ();
         this.close()



        }
        
      }
      }
    },

    

         mounted () {
           this.$validator.localize('en', this.dictionary)
            this.initialize ();
        
         }

  }
</script>