<template>
    <div>
        <v-toolbar flat color="white">
            <v-toolbar-title class="dodo">ألمشاركات</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>

            <v-select v-model="editedItem.posts_filter_id" :items="posts_status" item-text="name" item-value="id"
                :rules="[v => !!v || 'يجب ادخال اسم القسم']" label="أختار حال المشاركات" required
                @change="status_filter()"></v-select>

            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" persistent max-width="770px">

                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container grid-list-md>


                            <v-layout row wrap>


                                <v-flex xs12 sm3 md3>
                                    <p class="dodo"><span style="color:blue">أسـم المـرسل : </span>{{editedItem.name}}
                                    </p>
                                </v-flex>
                                <v-flex xs12 sm3 md3>
                                    <p class="dodo"> <span style="color:blue">ألايـميل : </span>{{editedItem.email}}
                                    </p>
                                </v-flex>
                                <v-flex xs12 sm3 md3>
                                    <p class="dodo"><span style="color:blue">رقـم الـهاتف : </span>{{editedItem.phone}}
                                    </p>
                                </v-flex>


                                <v-flex xs12 sm3 md3>
                                    <p><span style="color:blue" class="dodo">تاريخ الارسال :
                                        </span>{{editedItem.created_at}} </p>
                                </v-flex>

                               
                                
                             

                                 



                                <v-flex xs12 sm6 md12>
                                    <v-text-field v-model="editedItem.title" class="form_label" label="عنوان المشاركه">
                                    </v-text-field>
                                </v-flex>

                                         <v-flex xs6 sm3 md3>
                                    <v-autocomplete v-model="editedItem.article_type_id" :items="article_types"
                                        item-text="name" item-value="id" label="نـوع المقاله" cache-items hide-no-data>
                                    </v-autocomplete>
                                </v-flex>

                               




                                <v-flex xs12 sm3 md3>
                                    <v-autocomplete v-model="editedItem.journal_id" :items="journal_nums"
                                        item-text="journal_num" item-value="journal_id" label="نـشر في العدد"
                                        cache-items hide-no-data></v-autocomplete>
                                </v-flex>

                              


                                <v-flex xs12 sm3 md3>
                                    <v-autocomplete v-model="editedItem.state_id" :items="posts_status" item-text="name"
                                        item-value="id" label="حاله المقاله" cache-items hide-no-data></v-autocomplete>
                                </v-flex>

                                


                                <v-flex xs12 sm3 md3>
                                    <v-autocomplete v-model="editedItem.cat_id" :items="cats" item-text="cat_name"
                                        item-value="id" label="ألقسم" cache-items hide-no-data></v-autocomplete>
                                </v-flex>






                                <v-flex xs12 sm12 md12>
                                    <v-textarea v-model="editedItem.details" autofocus browser-autocomplete
                                    height="180px"
                                    outline
                                        label="التفاصيـــل" data-vv-name="name">
                                    </v-textarea>
                                </v-flex>
                                 <v-flex xs12 md6 sm6>
                                           صوره المشاركه

                                     </v-flex>

                                       <v-flex xs12 md6 sm6>
                                           صوره المشارك

                                     </v-flex>


                                    <v-flex xs12 sm6 md6>
                                  
                                            <img :src="imageUrl" v-if="imageUrl"   width="170px" height="130px" />
                                        <v-text-field label="أختار الصور المقاله" @click='pickFile'
                                            v-model='imageName' prepend-icon='fa-solid fa-paperclip'
                                             style="width:70%"
                                            ></v-text-field>
                                        <input type="file" style="display: none" ref="image" accept="image/*"
                                            @change="onFilePicked">
                                       </v-flex>

                                      


                                       <v-flex xs12 sm6 md6>
                                    <img :src="Url+'writers_img/thumb/'+editedItem.user_img"
                                        alt="" style="width:140px;height:140px">

                                         <!--  <v-btn v-on:click="Delete_Writer_pic(editedItem.writer_id)">Deleted
                                </v-btn>-->
                                </v-flex>




                                <!-- <v-flex xs12 sm6 md6>
                                    <p class="dodo" v-if="editedItem.main_img !=null"><span style="color:blue">ألملف :
                                        </span><a :href="Url+'posts_file/'+editedItem.main_img"
                                            target="_blank">أضغط هنا</a> </p>
                                </v-flex> -->

                              


                       


                            </v-layout>



                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" class="dodo" flat @click="close">خروج</v-btn>
                        <v-btn color="blue darken-1" class="dodo" flat @click="save">حفظ</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        <v-data-table :headers="headers" :items="posts" class="elevation-1"   total-items="15" hide-default-footer
  disable-pagination>
            <template slot="items" slot-scope="props">

                <td>{{ props.item.title }}</td>
                <td class="text-xs-right">{{ props.item.name }}</td>
                <td class="text-xs-right">{{props.item.email}}</td>
                <td class="text-xs-right">{{props.item.phone}}</td>
                <td class="text-xs-right">

                    <v-icon :color="props.item.color" dark right>{{props.item.icon}}</v-icon>

                </td>
                <td class="text-xs-right" style="font-family: auto;">{{props.item.created_at|moment("MM-DD-YYYY")}}</td>
              
            </template>
            <template v-slot:[`item.actions`]="{ item }">
     
     <v-icon
         small
         class="mr-2"
         @click="editItem(item)"
       >
       تعديل
       </v-icon>
       |
       <v-icon
         small
         @click="deleteItem(item)"
       >
         حذف
       </v-icon>
       


</template>
            
        </v-data-table>

        <v-pagination class="pagination" total-visible="20" v-model="page"
      color="#c7000b" style="position: relative; top: 20px;" circle=""
      :length="pageCount">
    </v-pagination>
    </div>
</template>


<script>
 import axios from 'axios';
 import swal from 'sweetalert2';
    export default {
        data() {
            return {
                attachments: [],
                radios: 'radio-1',
                form: new FormData,
                dialog: false,
                imagesUrl: '',
                imageName: '',
                img_old: '',
                article_types: [],
                imagesName: ' ',
                search: null,
                filez: [],
                arts_status: [],
                imageFile: [],
                imageUrl: '',
                page: 1,
        pageCount: 0,
        current_page: 1,
        last_page: 0,
                journal_nums: [],
                imageFile: '',
                posts: [],
                cats: [],
                headers: [



                    {
                        text: 'عنوان المشاركه',
                        align: 'left',
                        value: 'title',
                        sortable: false
                    },
                    {
                        text: 'أسم المشارك',
                        value: 'name',
                        sortable: false,
                    },
                    {
                        text: 'ألايميل',
                        value: 'email',
                        sortable: false,
                    },
                    {
                        text: 'ألهاتف',
                        value: 'phone',
                        sortable: false,
                    },
                    {
                        text: 'ألحاله',
                        value: 'article_status_id',
                        sortable: false,
                    },
                    {
                        text: 'تاريخ الارسال',
                        value: 'created_at',
                        sortable: false,
                    },

                    {
                        text: 'Actions',
                        value: 'actions',
                        sortable: false,
                        sortable: false,
                    }

                ],
                data: '',
                desserts: [],
                editedIndex: -1,
                editedItem: {
                    name: '',
                    article_types: [],
                    email: '',
                    phone: '',
                    file_name: '',
                    password: '',
                    state_id: '',
                },
                defaultItem: {
                    name: '',
                    email: '',
                    phone: '',
                    password: '',
                    file_name: '',
                    state_id: '1',

                },
                def: {
                    name: 'sds',


                }

            }
        },

       computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'أضافه كاتب جديد' : 'تعديل معلومات كاتب'
            },selected: function () {
        return this.getMoreitems();
      }
        },

          watch: {

      selected: 'search by sub_cat_id',

    },
        dialog(val) {
            val || this.close()
        },


        created() {

            Fire.$on('status_fliter', () => {
                console.log('status_fliter');
                axios.get('post_status_fliter/' + this.editedItem.posts_filter_id).then(response => (this
                    .posts = response.data))

            });
            this.article_type()
            this.JournalNumber()
            this.posts_state()
            this.initialize()
            this.categories()






        },

        methods: {
            getMoreitems() {
        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
          this.initialize();

        }
      },
           /*
           Delete_Writer_pic(writer){


                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    // Send request to the server
                    if (result.value) {
                        axios.post('attach_deleted/' + imgs.id).then(() => {
                            this.$swal(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            this.initialize()
                        }).catch(() => {
                            this.$swal("Failed!", "There was something wronge.", "warning");
                        });
                    }
                })


            },
            */

            pickFile() {
                this.$refs.image.click()
            },

            JournalNumber() {

                axios.get('journal_num').then(response => (this.journal_nums = response.data))

            },

            pickFiles() {
                this.$refs.images.click()
                console.log('h1');
            },


            onFilesPicked(e) {

                let selectedFiles = e.target.files;
                if (!selectedFiles.length) {
                    return false;
                }
                for (let i = 0; i < selectedFiles.length; i++) {
                    this.attachments.push(selectedFiles[i]);
                }



            },

            onFilePicked(e) {


                const files = e.target.files


                if (files[0] !== undefined) {



                    this.imageName = files[0].name


                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }


                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {

                        this.imageUrl = fr.result
                        this.imageFile = files[0]

                    })
                } else {
                    this.imageName = ''
                    this.imageFile = ''
                    this.imageUrl = ''


                }
            },



            status_filter() {


                Fire.$emit('status_fliter');
            },

            initialize() {

                   axios.get('art_posts?page=' + this.current_page,{
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          })

     
        
        .then(d => {
          this.posts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })




        
            },

            posts_state() {
                axios.get('art_status').then(response => (this.posts_status = response.data))
            },

            article_type() {
                axios.get('article_types').then(response => (this.article_types = response.data))
            },

            JournalNumber() {

                axios.get('journal_num').then(response => (this.journal_nums = response.data))

            },

            categories() {
                axios.get('listcats').then(response => (this.cats = response.data))
            },



            editItem(item) {




                this.editedIndex = this.posts.indexOf(item)
                this.editedItem = Object.assign({}, item)

                this.dialog = true

                // if (this.imageUrl[0] == null) {

                //      this.img_old = this.editedItem.main_img;
                //     this.imageUrl = this.Url+'img_art/thumb/' + this.img_old;

                // }

                this.img_old = this.editedItem.main_img;
                    this.imageUrl = this.Url+'img_art/thumb/' + this.img_old;
            },

            deleteItem(item) {


                const index = this.desserts.indexOf(item)
                //confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)


                this.$swal({
                    title: 'Are you sure?',

                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    // Send request to the server
                    if (result.value) {
                        axios.delete('art_deleted/' + item.id,{
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  }).then(() => {
                    this.$swal(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            this.initialize()
                        }).catch(() => {
                            this.$swal("Failed!", "There was something wronge.", "warning");
                        });
                    }
                })



            },

            sendmail(item) {


                const index = this.desserts.indexOf(item)
                //confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)


                this.$swal({
                    title: 'أرسال رساله',

                    text: "هل ترغب بارسال رساله الى الكاتب لتبليغه  بنشر مقالته ",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    // Send request to the server
                    if (result.value) {
                        axios.post('mail_art_published/' + item.id,{
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorization: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  }).then(() => {
                    this.$swal(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            this.initialize()
                        }).catch(() => {
                            this.$swal("Failed!", "There was something wronge.", "warning");
                        });
                    }
                })



            },



            close() {
            
                this.dialog = false
              
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                }, 300)
            },

            save() {



                axios.post('art_update/' + this.editedItem.id, {

                    title: this.editedItem.title,
                    details: this.editedItem.details,
                    journal_id: this.editedItem.journal_id,
                    writer_id: this.editedItem.writer_id,
                    art_type_id: this.editedItem.article_type_id,
                    art_status_id: this.editedItem.state_id,
                    cats_id: this.editedItem.cat_id,

                },{
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  })
                     .then(() => {

                            if (this.imageFile != null) {

                                let formData = new FormData();
                                formData.append('photo', this.imageFile);
                                axios.post('update-img/' + this.editedItem.id,
                                    formData, {
                                        headers: {
                                            'Content-Type': 'multipart/form-data',
                                            Accept: "application/json",
                                            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                                        }
                                    }
                                )
                                this.initialize();
                                this.imageName = ''
                                this.imageFile = ''
                                this.imageUrl = ''
                                
                            }


                          




                            this.dialog = false
                            toast({
                                type: 'success',
                                title: 'Signed in successfully'
                            })


                        }).catch(() => {
                            this.initialize();
                        });
                    this.initialize();

                this.close();
            

            },
        },

        mounted() {
            this.initialize();

        }

    }

</script>
