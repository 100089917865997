<template>
  <div>


    <v-toolbar flat >
      <v-toolbar-title class="dodo">المكتبة المرئية</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>


      <v-layout row wrap>
        
        <v-flex xs12 sm4>
          <br>
          <br>

          <v-select v-model="editedItem.cat_filter_id" :items="video_types" item-text="name" item-value="id"
            label="قسم الفيديو" required @change="cats_filter();is_search=true;current_page=0" return-object persistent-hint
            v-on:input="cats_filter(`${editedItem.cat_filter_id.id}`)"></v-select>



        </v-flex>


     
      </v-layout>

      <v-spacer></v-spacer>

      <!--Uploude Mul File-->
      <v-dialog v-model="dialog_mul" max-width="700px">

        <v-card>
          <v-card-title>
            <span class="dodo">الصور المرفقه</span>
          </v-card-title>



          <v-container grid-list-md>
            <v-layout row wrap>
              <v-form v-model="valid" ref="formx">
                <input id="attachments" type="file" multiple="multiple" @change="uploadFieldChange">

                <progress max="100" :value.prop="uploadPercentage"></progress>
                <br>
                <br>
              </v-form>

            </v-layout>

            <v-layout row wrap>



              <v-flex v-for="imgs in attached_images" :key="imgs.id">
                <div style="width:80px;height:80px">

                  <img v-bind:src="Url+'img_art/thumb/'+imgs.img_name" style="width:100%;height:100%;">

                </div>

                <v-btn v-on:click="deletepic(imgs)">Deleted
                </v-btn>
              </v-flex>

            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="success" @click="close_mul" style="margin-left:15px;">Cancel</v-btn>
            <v-btn color="error" :loading="loade" @click="save_mul">Save</v-btn>

          </v-card-actions>

        </v-card>
      </v-dialog>
      <!--Uploude Mul File-->


      <v-dialog v-model="dialog" persistent max-width="1300px" max-height="1300px">
        <v-btn slot="activator" color="primary" dark class="dodo mb-2" @click="dialog=true">فيديو جديد</v-btn>
        <v-form v-model="valid" ref="form">
          <v-card>

            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>

                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.title" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="العنوان"></v-text-field>
                  </v-flex>


                  <!-- <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.source" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="المصدر"></v-text-field>
                  </v-flex> -->






                  <v-flex xs6 sm6 md6>
                    

                    <v-autocomplete v-model="editedItem.videos_types_id" :items="video_types" item-text="name"
                      item-value="id" label="قسم الفيديو" :rules="ArtTypeRules" hide-no-data>
                    </v-autocomplete>
                  </v-flex>


                  <v-flex xs6 sm6 md6>
                    <v-autocomplete v-model="editedItem.active" :items="posts_status" item-text="name"
                      item-value="id" label="حاله الفيديو"  hide-no-data></v-autocomplete>
                  </v-flex>



                  <!-- <v-flex xs12 sm12 md12>
                    <v-textarea v-model="editedItem.text" autofocus browser-autocomplete label="التفاصيـــل"
                      data-vv-name="name">
                    </v-textarea>
                  </v-flex> -->



                  <v-flex xs6>
                    <v-text-field label="أختار الصوره الرئيسية للفيديو" @click='pickFile' v-model='imageName'
                      prepend-icon='fa-solid fa-paperclip'></v-text-field>
                    <input type="file" style="display: none" ref="image" accept="image/*" @change="onFilePicked">
                  </v-flex>

                  <v-flex xs6>
                    <img :src="imageUrl" height="150" v-if="imageUrl" />
                  </v-flex>



                  <!-- <v-flex xs6>
                    <input type="file" multiple="multiple" id="attachments" @change="uploadFieldChange">
                  </v-flex> -->



                  
                </v-layout>

                <v-col class="py-0" cols="6" sm="6" md="6" v-if="editedIndex == -1">
                            <v-text-field label="أرفاق الفيديو " @click='pickFile2' v-model='file_name' id="file"
                              prepend-icon='fas fa-paperclip'>
                            </v-text-field>

                            <input type="file" style="display: none" ref="file" id="file"
                              accept="application/application/,.mp4," v-on:change="onPickfile2">
                          </v-col>


                <v-layout row wrap>
                  <v-flex xs6 v-for="imgs in attached_images" :key="imgs.id">
                    <div style="width:140px;height:160px">

                      <img v-bind:src="Url+'/img_art/thumb/'+imgs.img_name" style="width:100%;height:100%;">

                    </div>
                    {{imgs.id}}
                    <v-btn v-on:click="deletepic(imgs)">Deleted

                    </v-btn>
                  </v-flex>
                  <v-flex xs2>

                  </v-flex>
                </v-layout>







              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" :loading="load" flat @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-toolbar>
    <v-data-table :headers="headers" :items="arts" class="elevation-1" :items-per-page="15" hide-default-footer
      disable-pagination>


      <template slot="no-data">
        <v-btn color="primary" @click="initialize">Reset</v-btn>


      </template>

      <template v-slot:[`item.active`]="{ item }">



        <span v-if="item.active==1">فعال</span>
        <span v-else>غير فعال </span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <!-- <v-icon small @click="mul_img(item)" class="mr-2">
          fas fa-images
        </v-icon>
        | -->

        <v-icon small class="mr-2" @click="editItem(item)">
          تعديل
        </v-icon>
        |
        <v-icon small @click="deleteItem(item)">
          حذف
        </v-icon>



      </template>

      <v-pagination class="pagination" total-visible="20" v-model="page" color="#c7000b"
        style="position: relative; top: 20px;" circle="" :length="pageCount">
      </v-pagination>

    </v-data-table>

    <v-pagination class="pagination" total-visible="20" v-model="page" color="#c7000b"
      style="position: relative; top: 20px;" circle="" :length="pageCount">
    </v-pagination>


  </div>
</template>


<script>
  import swal from 'sweetalert2'

  import axios from 'axios'
  export default {

    props: [
      'settings'
    ],
    data() {
      return {
        load: false,
        data: new FormData(),
        errors: {},
        page: 1,
        pageCount: 0,
        current_page: 1,
        last_page: 0,
        percentCompleted: 0,

        nameRules: [
          (v) => !!v || 'يجب ادخال عنوان الفيديو',

        ],

        activeRules: [
          (v) => !!v || 'يجب ادخال حالة الفيديو',

        ],

        ArtTypeRules: [
          (v) => !!v || 'يجب ادخال قسم الفيديو',

        ],
        uploadPercentage: 0,
        form: new FormData,
        video_types:[],
        attachments: [],
        attached_images: [],
        form: new FormData,
        valid: false,
        dialog: false,
        imagesUrl: '',
        imageName: '',
        loade: false,
        img_old: '',
        file_name: null,
        posts_status: [
          {
            id:1,
            name:'نشر',

          },
          {
            id:0,
            name:'في الانتظار',

          }
        ],
        writers: [],
        imagesName: ' ',
        search: null,
        filez: [],
        imageFile: [],
        imageUrl: '',
        journal_nums: [],
        imageFile: '',
        arts: [],
        view_ats: [],
        cats: [],
        dialog_mul: false,
        headers: [


          {
            text: 'ID',
            value: 'id',
            sortable: false,
          },
          {
            text: 'ألعنوان',
            align: 'left',
            value: 'title',
            align: "right",
            sortable: false,

          },


          {
            text: 'قسم الفيديو',
            value: 'videos_types.name',
            sortable: false,
          },
          {
            text: 'تاريخ النشر',
            value: 'created_at',
            sortable: false,
          },
          {
            text: 'حاله الفيديو',
            value: 'active',
            sortable: false,
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            sortable: false
          }

        ],

        desserts: [],
        editedIndex: -1,
        editedItem: {
          name: '',
          email: '',
          phone: '',
          picsid: '',
          password: '',
          state_id: '',
        },
        defaultItem: {
          name: '',
          email: '',
          phone: '',
          password: '',

        },
        def: {
          name: 'sds',


        }

      }
    },

    computed: {
      formTitle() {
        return this.editedIndex === -1 ? 'أضافه مقاله جديده' : 'Edit Item'
      }
    },

    watch: {

      selected: 'search by sub_cat_id',

    },
    computed: {
      selected: function () {
        return this.getMoreitems();
      }
    },
    dialog(val) {
      val || this.close()
    },


    created() {
      this.initialize()
      this.article_type()
      this.start();
      Fire.$on('cats_fliter', () => {

        axios.get('video_catid/' + this.editedItem.cat_filter_id.id)
          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })


      });

      Fire.$on('viewAt_filter', () => {

        axios.get('art_by_viewat/' + this.editedItem.view_filter_id.id).then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })


      });








      //this.view_at()
      

      //this.posts_state()



    },

    methods: {
      close_mul() {

        this.uploadPercentage = 0,

          setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          }, 300)

        this.dialog_mul = false
      },

      save_mul() {

        this.prepareFields();
        this.loade = true;
        /*Star Uploude Multiple File*/
        var config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: "application/json",
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          },

          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent

              .total));
            //   this.dialog_mul = false
          }.bind(this)



        };
        // Make HTTP request to store announcement

        axios.post('attached_images_uplude/' + this.editedItem.id, this.data, config)
          .then(() => {
            axios.get('attached_images/' + this.editedItem.id).then(response => (this.attached_images = response
              .data));
            this.loade = false;
            this.$swal.fire('Successfully', 'done', "success");
            this.resetData();
            this.errors = response.data.errors;






          }).catch(() => {


          });








      },
      getMoreitems() {
   


        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
          if(this.is_search){
          
          
            axios.get('video_catid/' + this.editedItem.cat_filter_id.id+'?page=' + this.current_page)
          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
           this.pageCount = d.data.last_page;

         

           
          })


          }
       else{
        this.initialize();
       }

        }

      },
      getAttachmentSize() {

        this.upload_size = 0;
        this.attachments.map((item) => {
          this.upload_size += parseInt(item.size);
        });
        this.upload_size = Number((this.upload_size).toFixed(1));
        this.$forceUpdate();
      },


      prepareFields() {

        if (this.attachments.length > 0) {
          for (var i = 0; i < this.attachments.length; i++) {
            let attachment = this.attachments[i];
            this.data.append('attachments[]', attachment);
          }

        }
      },


      removeAttachment(attachment) {

        this.attachments.splice(this.attachments.indexOf(attachment), 1);

        this.getAttachmentSize();
      },


      mul_img(item) {



        this.editedIndex = this.arts.indexOf(item)
        this.editedItem = Object.assign({}, item)

        this.dialog_mul = true


        axios.get('attached_images/' + this.editedItem.id).then(response => (this.attached_images = response.data));




        // if (this.imageUrl[0] == null) {

        //     this.img_old = this.editedItem.main_img;
        //     this.imageUrl = 'https://alkafeel.net/sadda/img_art/thumb/' + this.img_old;

        // }
      },


      uploadFieldChange(e) {


        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        for (var i = files.length - 1; i >= 0; i--) {
          this.attachments.push(files[i]);
        }

        // Reset the form to avoid copying these files multiple times into this.attachments
        document.getElementById("attachments").value = [];

        console.log(this.attachments);
      },


      resetData() {
        this.data = new FormData(); // Reset it completely
        this.attachments = [];
      },
      start() {
        console.log('Starting File Management Component');
      },


      deletepic(imgs) {


        this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: 'yes',
          cancelButtonText: 'no',
        }).then((result) => {
          // Send request to the server
          if (result.value) {
            axios.delete('attached_images/' + imgs.id, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              },
            }).then(() => {
              this.$swal.fire('Successfully', 'done', "success");

              axios.get('attached_images/' + this.editedItem.id).then(response => (this.attached_images =
                response.data));

            }).catch(() => {
              swal("Failed!", "There was something wronge.", "warning");
            });
          }
        })


      },

      pickFile() {
        this.$refs.image.click()
      },


      pickFiles() {
        this.$refs.images.click()

      },

      pickFile2() {
        this.$refs.file.click()
      },
      onPickfile2(e) {

        this.file = this.$refs.file.files[0];
        let file = e.target.files;
        this.file = file[0]
        this.file_name = file[0].name
      },
      onFilesPicked(e) {

        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        for (var i = files.length - 1; i >= 0; i--) {
          this.attachments.push(files[i]);
        }
        console.log(this.attachments);
        document.getElementById("attachments").value = [];

      },

      onFilePicked(e) {


        const files = e.target.files


        if (files[0] !== undefined) {



          this.imageName = files[0].name
          // console.log( this.imageName );

          if (this.imageName.lastIndexOf('.') <= 0) {
            return
          }


          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {

            this.imageUrl = fr.result
            this.imageFile = files[0]


          })
        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''


        }
      },



      cats_filter() {


        Fire.$emit('cats_fliter');
      },

      viewAt_filter() {
        console.log('view at here');
        Fire.$emit('viewAt_filter');
      },


      initialize() {

        axios.get('videos?page=' + this.current_page, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizationss: "Bearer " + this.$store.state.AdminInfo.token
            }
          })



          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })


      },



      article_type() {
        axios.get('video_types').then(response => (this.video_types = response.data))
      },




      // view_at() {
      //   axios.get('view_ats').then(response => (this.view_ats = response.data))
      // },



      editItem(item) {




        this.editedIndex = this.arts.indexOf(item)
        this.editedItem = Object.assign({}, item)

        this.dialog = true



        //axios.get('attached_images/' + this.editedItem.id).then(response => (this.attached_images = response.data));


        if (this.imageUrl[0] == null) {

          this.img_old = this.editedItem.img;
          this.imageUrl = 'http://alfkrya.com/img/small/' + this.img_old;

        }
      },

      deleteItem(item) {


        const index = this.desserts.indexOf(item)
        //confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)


        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          // Send request to the server
          if (result.value) {
            axios.delete('videos/' + item.id, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              },
            }).then(() => {
              this.$swal.fire('Successfully', 'done', "success");
              this.initialize()
            }).catch(() => {
              swal("Failed!", "There was something wronge.", "warning");
            });
          }
        })



      },

      close() {
        this.dialog = false
        this.imageUrl = '';
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      async save() {
        this.load = true;
        this.prepareFields();
        if (this.editedIndex > -1) {
        // Edit existing item
        await axios.put('videos/' + this.editedItem.id, {
          title: this.editedItem.title,
          active: this.editedItem.active,
          videos_types_id: this.editedItem.videos_types_id,
        }, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          }
        }).then(() => {
          if (this.imageFile != null) {
            let formData = new FormData();
            formData.append('photo', this.imageFile);
            axios.post('update_img/' + this.editedItem.id, formData, {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              }
            });
            this.imageName = '';
            this.imageFile = '';
            this.imageUrl = '';
            this.load = false;
            this.initialize();
          }
          this.dialog = false;
          this.$swal.fire('Successfully', 'done', "success");
          }).catch(() => {});
          this.initialize();

        } else {

          if (this.$refs.form.validate()) {
            // Save new item
            if (this.imageFile == '') {
              this.$swal({
                title: "يجب اختيار صوره",
                text: "",
                icon: "error",
                confirmButtonText: "اغلاق",
              });
              return;
            }
            if (this.file == null) {
              this.$swal({
                title: " يجب اختيار الفيديو",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
              });
              return;
            }

            const res = await axios({
              method: 'post',
              url: 'videos',
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              },
              data: {
                title: this.editedItem.title,
                active: this.editedItem.active,
                videos_types_id: this.editedItem.videos_types_id,
              },
            });

            // Save video image
            let formData = new FormData();
            formData.append('photo', this.imageFile);
            await axios.post('video_uploude-img/' + res.data.id, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              }
            });


            // Save video file || mp4
            let formDataFile = new FormData();
            formDataFile.append('video', this.file);
            await axios.post('uploadVideo/' + res.data.id, formDataFile, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              }
            });



            this.$swal.fire('Successfully', 'done', "success");
            this.dialog = false;
            this.load = false;
            this.file_name = '';
            this.file = '';
            this.imageFile = '';
            this.imageName = '';



            // await axios({
            //     method: 'post',
            //     url: 'videos',
            //     headers: {
            //       "Content-Type": "application/json",
            //       Accept: "application/json",
            //       Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            //     },
            //     data: {
            //       title: this.editedItem.title,
            //       active: this.editedItem.active,
            //       videos_types_id: this.editedItem.videos_types_id,       
            //     },
            //   }).then((res) => {
            //     // save video image
            //     let formData = new FormData();
            //     formData.append('photo', this.imageFile);
            //     axios.post('video_uploude-img/'+res.data.id,
            //       formData, {
            //         headers: {
            //           'Content-Type': 'multipart/form-data',
            //           Accept: "application/json",
            //           Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            //         }
            //       },
            //     ).then(() => {
            //     // save video file || mp4
            //     let formDataFile = new FormData();
            //     formDataFile.append('video', this.file);
            //     axios.post('uploadVideo/'+res.data.id,
            //     formDataFile, {
            //         headers: {
            //           'Content-Type': 'multipart/form-data',
            //           Accept: "application/json",
            //           Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            //         }
            //       },
            //     ).then(() => {
            //       this.$swal.fire('Successfully', 'done', "success");
            //       this.dialog = false;
            //       this.file_name = '';
            //       this.file = '';
            //       this.imageFile = '';
            //       this.imageName = '';
            //     });
            //     });

            //   });

            this.initialize();
            this.close()



          }

        }
      }
    },



    mounted() {
      this.$validator.localize('en', this.dictionary)
      this.initialize();

    }

  }
</script>